import { React, memo } from "react";
import { Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const HomeCard = ({ item, location, value, icon, condition,isEnable }) => {
  const history = useHistory();
  
  return (
    <Col
      xs="12"
      sm="6"
      md="4"
      lg="4"
      className="justify-content-center mh-100 mb-4"
    >
      <Button
        onClick={() => {
          history.push(location);
        }}
        className={"btn btn-primary justify-content-center d-flex align-items-center btn-block h-100 flex-column grid-link pt-5 pb-5"}
        disabled={!isEnable}
      >
        {icon}
        {value > condition && value>0 ? (
          <>
            <span className="badge badge-warning position-absolute">
              {value}
            </span>
          </>
        ) : (
          ""
        )}
        {item}
      </Button>
    </Col>
  );
};

export default memo(HomeCard);
