import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Twitter from "../assets/twitter.svg";
import Facebook from "../assets/facebook.svg";
const Footer = () => {
  return (
    <footer className="bg-dark pt-5 pb-5">
      <Container>
        <Row>
          <Col className="d-md-flex justify-content-md-between align-items-md-center">
            <small className="text-white text-center mb-4 d-block">
              &copy;2023 FBROF | All Rights Reserved
            </small>
            <div className="d-flex justify-content-center justify-content-md-start align-items-md-center">
              <a href="https://www.google.com">
                <Image src={Twitter} alt="Twitter" className="mr-4" />
              </a>
              <a href="https://www.google.com">
                <Image src={Facebook} alt="Facebook" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
