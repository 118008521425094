import React, { useContext } from "react";
import "./LandingGrid.css";
import { Col, Container, Row } from "react-bootstrap";
import { LandingContext } from "./LandingContext";
import constValues from "../../constants/constantValues.json";
import { store } from "../../contexts/store";
import HomeCard from "../HomeCard";
import HomeData from "../../constants/HomeData";

const LandingGrids = () => {
  const LandingModel = useContext(LandingContext);
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const assignValue = (currentItem) => {
    const notificationString = HomeData?.[constValues.FIRST_VALUE_INDEX]?.item;
    if (currentItem === notificationString)
      return LandingModel.notificationCount;
  };
  return (
    <section className="middle-wrap pt-5 pb-5">
      <Container>
        <Row className="justify-content-md-center blue-land-btns">
          <Col xs sm="12" md="12" lg="8">
            <Row className="grid-row">
              {HomeData &&
                HomeData?.map((data) => {
                  return (
                    <HomeCard
                      key={data?.id}
                      item={data?.item}
                      location={data?.location}
                      icon={data?.icon}
                      value={assignValue(data?.item)}
                      condition={constValues?.NOTIFICATION_VALUE_CONDITION}
                      isEnable={
                        data?.item === "Voting"
                          ? globalState.state.voting ||
                            globalState.state.votingEnableCount === 1
                          : data?.item === "Results"
                          ? globalState.state.declareResults ||
                            globalState.state.publishResultEnableCount === 1
                          : data?.item === "EC 2023 - 25"
                          ? globalState.state.newCommittee ||
                            globalState.state.OnboardNewCommitteeEnableCount ===
                              1
                          : data?.item === "Nominations" ?((globalState.state.submitNominations && globalState.state.user?.isElectionPanel !== "Y")||globalState.state.nominationEnableCount===1 && globalState.state.user && globalState.state.user?.isElectionPanel !== "Y"):
                          (data?.item === "Candidate List"?(globalState.state.publishCandidateList||globalState.state.publishCandidateListEnableCount===1):true)
                      }
                    />
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default LandingGrids;
