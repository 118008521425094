import React from "react";
import { Col, Nav, Card , Button} from "react-bootstrap";
import "../containers/Voting.css";

let votelst = [];
let nominationlist = [];
let defaultPosId = "";
const PositionsList = ({
  data,
  showVoteColours,
  onTabSelect,
  voteList,
  catidatesData,
  screen,
  setPositionName,
  setDefaultPosition,
  voted,
  votingState,
  onSubmit
}) => {
  defaultPosId = "";
  const filterPosition = (positionId, totalPositions, screen, positionName) => {
    if (catidatesData) {
      nominationlist = catidatesData.filter((x) => x.positionId === positionId);
      if (
        (nominationlist && nominationlist.length > totalPositions) ||
        screen === "candidates"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      <Col sm={3}>
        <Card className="voting-left">
          <Card.Body>
            <Nav variant="pills" className="flex-column">
              {data &&
                data.map((item, index) => {
                  if (voteList && voteList.length > 0) {
                    votelst = voteList.filter((x) => x.positionId === item._id);
                  }
                  if(catidatesData){
                    nominationlist=catidatesData.filter(x=>x.positionId===item._id);
                  }
                  return (
                    <>
                      {filterPosition(item._id, item.totalPositions, screen, item.name)===true && (
                        <Nav.Item key={`position-${index}`}>
                          <Nav.Link
                            eventKey={item._id}
                            onClick={() =>
                              onTabSelect(
                                item._id,
                                item.name,
                                item.totalPositions
                              )
                            }
                            className={
                              showVoteColours
                                ? votelst &&
                                  votelst.length > 0 &&
                                  votelst[0].candidateId.length>0
                                  ? "bgsuccess"
                                  : "bgwarning"
                                : ""
                            }
                          >
                            {item.name}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </>
                  );
                })}
                  {!voted && votingState &&
                <Button variant="danger" size="lg" 
                className="mt-2 submit-btn" onClick={()=>onSubmit()}>FINAL SUBMIT</Button>
                }
            </Nav>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default PositionsList;
