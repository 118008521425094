import React, { useState, useContext } from "react";

import './TermsBlock.css'

import "../components/TermsBlock.css";
import { Button, Form, Modal } from "react-bootstrap";
import "../containers/nomiationPagestyle.css";
import Icons from "../components/Icons";
import { store } from "../contexts/store";

const TermsBlock = ({
  candidateName,
  confirmationStatus,
  declarationStatus,
  confirmation,
  declaration,
  OnSubmitClick,
  OnCancelClick,
  onBacktoHomeClick,
  onClosePopup,
  showSuccess,
  confirmationGender,
  showGenderConfirmation,
  setConfirmationGender,
  positionName,
  setSubmitDisable,
  submitDisable,
}) => {
  const globalState = useContext(store);
  const changeConfirmation = () => (
    confirmation(!confirmationStatus), setSubmitDisable(false)
  );
  const changeDeclaration = () => (
    declaration(!declarationStatus), setSubmitDisable(false)
  );
  const changeGenderConfirmation = () => (
    setConfirmationGender(!confirmationGender), setSubmitDisable(false)
  );

  return (
    <div>
      {positionName !== "" && (
        <div>
          <div className="d-flex justify-content-start pt-5 pb-2">
            <div className="mb-3 form-group">
              <div className="form-check">
                <input
                  checked={confirmationStatus}
                  onChange={changeConfirmation}
                  type="checkbox"
                  id="formBasicCheckbox green-form-chk"
                  className="form-check-input borderRed"
                  style={{ border: "red !important", display:'none' }}
                />
                <label title="" for="formBasicCheckbox green-form-chk">
                  <span className="form-check-label">{`I, ${candidateName}, do hereby offer myself as a candiate for the post of '`}</span>
                  <span className="b">{positionName}</span>
                  <span>{`' of the forum in the forthcoming election.`}</span>
                </label>
              </div>
            </div>
          </div>
          {showGenderConfirmation && (
            <div className="d-flex justify-content-start pb-2">
              <div className="mb-3 form-group">
                <div className="form-check">
                  <input
                    checked={confirmationGender}
                    onChange={changeGenderConfirmation}
                    type="checkbox"
                    id="formBasicCheckbox green-form-chk-gender-agree"
                    className="form-check-input borderRed"
                  />
                  <label
                    title=""
                    for="formBasicCheckbox green-form-chk-gender-agree"
                  >
                    <span className="form-check-label">{`I, declare that I am applying for the women category position of '`}</span>
                    <span className="b">{positionName}</span>
                    <span>{`'.`}</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-start pb-2">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckbox green-form-chk-agree"
            >
              <Form.Check
                type="checkbox"
                checked={declarationStatus}
                onChange={changeDeclaration}
                label="I, declare that I am member of the FBROF eligible to be
                    elected to the post, as per the Bye-Laws of the Forum. I further declare that my
                    subscription and other dues to the Forum has been fully remitted.
                    "
              />
            </Form.Group>
          </div>
          <div className="d-flex justify-content-center pt-3 pb-2">
            <Button variant="link" size="lg" onClick={() => OnCancelClick()}>
              CANCEL
            </Button>
            <Button
              variant="primary"
              size="lg"
              disabled={
                submitDisable === true ||
                globalState.state.submitNominations === false ||
                confirmationStatus === false ||
                declarationStatus === false ||
                (confirmationGender === false &&
                  showGenderConfirmation === true)
              }
              onClick={() => (OnSubmitClick(), setSubmitDisable(true))}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsBlock;
