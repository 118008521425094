import React,{ useContext } from "react";
import {VotingContext} from './VotingContext';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icons from '../Icons'

const VotingSuccessPopup = () => {
  const votingModel = useContext(VotingContext);
  const {
    votingSuccess,
    setVotingSuccess,
    OnBacktoHomeClick,
    voteMode,
    isSuccess,
    infoMessage
  } = votingModel;

  return (
<Modal className="home-modals" show={votingSuccess} >
    <Modal.Header className="contnt-center" closeButton onClick={()=>setVotingSuccess(false)}>
    <div className="check-circle-img">{isSuccess?Icons.successTick:Icons.info}</div>
        <Modal.Title >{isSuccess?(voteMode==="Submitted"?"Your Vote is recorded!":"Your votes are saved"):infoMessage}</Modal.Title>
    </Modal.Header>
    
    <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary" onClick={()=>(isSuccess?OnBacktoHomeClick():setVotingSuccess(false))}>{isSuccess?"BACK TO HOME":"Ok"}</Button>
    </Modal.Footer>
</Modal>
);
};

export default VotingSuccessPopup;
